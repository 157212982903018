/* eslint-disable camelcase */
/**
 * Calculate sums on Quote products
 *
 * @param {string} way
 * @param {object} params
 * @return {{
 *     totalListPrice: number,
 *     totalMargin: number,
 *     total: *,
 *     total_discount: number,
 *     totalDiscountPrice: number,
 *     totalPriceAfterAddDiscount: number,
 *     totalDiscountAmount: number,
 *     totalPriceAfterDiscount: number,
 *     totalCostPrice: number, totalTax: number
 * }|{
 *     totalListPrice: number,
 *     totalMargin: number,
 *     totalDiscountPrice: number,
 *     totalPriceAfterAddDiscount: number,
 *     totalDiscountAmount: number,
 *     totalPriceAfterDiscount: number,
 *     totalCostPrice: number,
 *     totalTax: number
 * }}
 * @this sAction
 * @TODO Stahnout aktualni verzi az bude na masteru
 */
export default function calcSums(way, params) {
    if (params.type === 'group') {
        return calcGroupSums(params.data, this);
    }

    const data = params.data;
    let total_cost_price = 0;
    let total_list_price = 0;
    let total_discount_amount = 0;
    let total_discount_price = 0;
    let total_tax = 0;
    let total_margin = 0;
    let total_price_after_discount = 0;
    let total_price_after_add_discount = 0;

    const self = this;

    data.groups.forEach(function(group) {
        const pom = calcGroupSums(group, self);
        total_cost_price += pom.total_cost_price;
        total_list_price += pom.total_list_price;
        total_discount_amount += pom.total_discount_amount;
        total_discount_price += pom.total_discount_price;
        total_tax += pom.total_tax;
        total_margin += pom.total_margin;
        total_price_after_discount += pom.total_price_after_discount;
        total_price_after_add_discount += pom.total_price_after_add_discount;
    });

    // must be in snake_case, because it is saved to store
    return {
        total_cost_price: total_cost_price,
        total_list_price: total_list_price,
        total_discount_amount: total_discount_amount,
        total_discount_price: total_discount_price,
        total_discount: total_discount_price,
        total_tax: total_tax,
        total_margin: total_margin,
        total: total_discount_price + self.parseNumber(data.shipping) + total_tax,
        total_price_after_discount: total_price_after_discount,
        total_price_after_add_discount: total_price_after_add_discount,
    };
}

/**
 *  @param {object} data
 *  @param {object} sAction
 *  @return {object}
 */
function calcGroupSums(data, sAction) {
    let totalCostPrice = 0;
    let totalListPrice = 0;
    let totalDiscountAmount = 0;
    let totalDiscountPrice = 0;
    let totalTax = 0;
    let totalMargin = 0;
    let totalPriceAfterDiscount = 0;
    let totalPriceAfterAddDiscount = 0;

    data.lines.forEach(function(prod) {
        if (prod.opce_c) {
            return;
        }
        const quantity = sAction.parseNumber(prod.quantity);
        const lineDiscountPrice = sAction.parseNumber(prod.list_price);

        totalCostPrice += sAction.parseNumber(prod.cost_price) * quantity;
        // totalListPrice += lineDiscountPrice;
        totalListPrice += lineDiscountPrice * quantity;
        // totalPriceAfterDiscount += sAction.parseNumber(prod.price_after_discount);
        totalPriceAfterDiscount += sAction.parseNumber(prod.price_after_discount) * quantity;

        let discount = sAction.parseNumber(prod.discount_amount);
        let priceListDiscount = sAction.parseNumber(prod.price_after_add_discount);
        let valAmount = 0;
        if (parseFloat(prod.discount_select) === 1) {
            discount = (sAction.parseNumber(prod.price_after_discount)/100)*discount;
            priceListDiscount = (sAction.parseNumber(prod.list_price)/100)*priceListDiscount;
        }

        totalPriceAfterAddDiscount += (priceListDiscount * quantity); // pocita slevu celkem s poctem kusu
        // total_price_after_add_discount += priceListDiscount   nebere v potaz mnozstvi

        if (prod.selected_mj === 'mj2') {
            valAmount = ((sAction.parseNumber(prod.list_price) - priceListDiscount) * prod.price_mj2) * prod.quantity;
        } else {
            valAmount = (sAction.parseNumber(prod.list_price) - priceListDiscount) * prod.quantity;
        }

        totalDiscountPrice += valAmount;
        totalDiscountAmount += discount;

        if (prod.tax_class !== 'Non-Taxable') {
            totalTax += (valAmount / 100) * prod.vat;
        }
    });

    totalMargin = totalDiscountPrice - totalCostPrice;

    // must be in snake_case, because it is saved to store
    return {
        total_cost_price: totalCostPrice,
        total_list_price: totalListPrice,
        total_discount_amount: totalDiscountAmount,
        total_discount_price: totalDiscountPrice,
        total_discount_price_with_vat: totalDiscountPrice + totalTax,
        total_tax: totalTax,
        total_margin: totalMargin,
        total_price_after_discount: totalPriceAfterDiscount,
        total_price_after_add_discount: totalPriceAfterAddDiscount,
    };
}
