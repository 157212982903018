/**
 * Get info about product prices from price lists
 *
 * @param {*} id
 * @param {*} type
 * @param {*} way
 * @param {*} index
 * @TODO It would like to recalculate the currency from the product not from template
 *
 */
export default function getProductTemplate(id, type, way, index = null) {
    this.load();
    const prefix = way.split('/')[0];
    const getFields = ['id', 'mj', 'name', 'cost_price', 'list_price', 'discount_price', 'currency_id', 'description',
        'product_type', 'percents', 'inherit', 'picture', 'category_id', 'price_mj', 'discount_price_mj', 'discount_price_mj',
        'product_description', 'mft_part_num', 'price_after_discount', 'kompetence_manager', 'kompetence_obchodnik', 'pricing_map',
        'quantity', 'mj2', 'price_mj2', 'price_after_add_discount', 'vat'];
    const data = {
        id: id,
        accountId: this.dataGet(`${prefix}/fields/billing_account_name/def/id_value`),
        getFields: getFields,
    };
    const fullWay = index !== null ? `${way}/${index}` : way;
    this.rest.fetchData('getProductTemplate', 'POST', data).then((returnData) => {
        returnData = returnData['data'];
        let line = returnData['product'];
        line = prepareProduct(this, type, prefix, line);
        this.updateDetailCustom(type, {way: fullWay, item: line, template: true});
        if (returnData['bound']) {
            if (returnData['bound']['same_group']) {
                returnData['bound']['same_group'].forEach((item) => {
                    item = prepareProduct(this, 'addProdLine', prefix, item);
                    this.updateDetailCustom('addProdLine', {way: way, item: item});
                });
            }
            if (returnData['bound']['new_group']) {
                const groupWay = way.split('groups')[0] + 'groups';
                const groupID = this.dataGet(groupWay, null, true).length;
                this.updateDetailCustom('addProdGroup', {way: groupWay, name: ''});
                returnData['bound']['new_group'].forEach((item) => {
                    item = prepareProduct(this, 'addProdLine', prefix, item);
                    this.updateDetailCustom('addProdLine', {way: `${groupWay}/${groupID}/lines`, item: item});
                });
            }
        }

        this.unLoad();
    }).catch(()=>{
        this.unLoad();
    });
}

/**
 *
 * @param {*} sAction
 * @param {*} type
 * @param {*} prefix
 * @param {*} line
 * @returns {*}
 */
const prepareProduct = (sAction, type, prefix, line) => {
    if (type !== 'prodName') {
        line.product_template_id = line.id;
        line.id = '';
    }
    // It would like to recalculate the currency from the product not from template
    let kurz = 0;
    const kurzFieldValue = sAction.dataGet(`${prefix}/fields/kurz/value`);
    if (kurzFieldValue && parseFloat(kurzFieldValue)) {
        kurz = kurzFieldValue;
    }
    // let kurz = sAction.dataGet(way.split('customData')[0] + 'fields/price_cur/value');
    // const currencyID = sAction.dataGet(way.split("/productData/", 1)[0]+"/currency_id");//TTT cesta natvrdo
    if (line.price_after_add_discount === 'FIRMA') {
        // when quote's account doesn't have product in price lists, apply discount from account
        line.price_after_add_discount = sAction.dataGet(`${prefix}/fields/account_discount/value`) ?? 0;
        line.price_after_discount = line.list_price * ((100 - line.price_after_add_discount) / 100 );
    }

    const currencyID = sAction.dataGet(`${prefix}/fields/currency_id/value`);
    // let convRate = sAction.dataGet(prefix+"/fields/kurz/value");
    let priceAfterAddDiscount = line?.pricing_map?.list_percent_discount ?? line.price_after_add_discount;
    // eslint-disable-next-line no-prototype-builtins
    if (line?.pricing_map?.hasOwnProperty('item_discount_type')) {
        line.discount_select = parseInt(line.pricing_map.item_discount_type);
        if (parseFloat(line.pricing_map.item_discount_type) === 0) {
            priceAfterAddDiscount = sAction.currencyConvert(priceAfterAddDiscount, line.currency_id, currencyID, kurz);
        }
    }
    line.initial_price_after_add_discount = line.price_after_add_discount = priceAfterAddDiscount;
    const listPrice = line?.pricing_map?.product_list_price ?? line.list_price ?? 0;
    const priceAfterDiscount = line?.pricing_map?.price_after_discount ?? line.price_after_discount ?? 0;

    line.cost_price = sAction.currencyConvert(line.cost_price, line.currency_id, currencyID, kurz);
    line.list_price = sAction.currencyConvert(listPrice, line.currency_id, currencyID, kurz);
    line.price_after_discount = sAction.currencyConvert(priceAfterDiscount, line.currency_id, currencyID, kurz);
    line.discount_amount = 0; // for right calculation price_after_add_discount, when changing ABS and %

    return line;
};
